@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,600;1,400;1,600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  --color-yellow: hsl(53deg 95% 50%);
  --color-yellow-dark: hsl(49deg 95% 49%);
  --color-yellow-pale: hsl(53deg 96% 63%);
  --color-gold: hsl(49deg 42% 35%);
  --color-white: hsl(0deg 0% 100%);
  --color-black: hsl(0deg 0% 0%);
  --color-darkgray: hsl(0deg 0% 25%);
  --color-black-75: hsla(0deg 0% 0% / 75%);
  --color-black-50: hsla(0deg 0% 0% / 50%);
  --color-black-25: hsla(0deg 0% 0% / 25%);

  font-family: Outfit, sans-serif;
  font-size: 20px;
  color: var(--color-black);
  background-color: var(--color-yellow);
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: var(--color-white);
}

::-webkit-scrollbar-thumb {
  background: var(--color-black);
  border: 5px solid var(--color-white);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-darkgray);
}
