.splash {
  aspect-ratio: 2 / 1;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.splash h2 {
  font-family: "Playfair Display", serif;
  color: var(--color-yellow);
  font-size: 48px;
  padding: 0.25em 0.5em;
  text-align: center;
}

.splash h2 span {
  background-color: var(--color-black-75);
}

.splash p {
  font-family: "Playfair Display", serif;
  color: var(--color-white);
  padding: 0.25em 0.5em;
  font-size: 32px;
  text-align: center;
  max-width: 75%;
}

.splash p span {
  background-color: var(--color-black-75);
}

.splash .call-to-action {
  background-color: var(--color-yellow);
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  padding: 0.75em 1em;
  text-decoration: none;
  color: var(--color-black);
  border: 4px solid var(--color-black);
  transition: color 0.25s, background-color 0.25s, border-color 0.25s;
}

.splash .call-to-action:is(:hover, :active, :focus) {
  background-color: var(--color-black);
  color: var(--color-yellow);
  border-color: var(--color-yellow);
}

@media (max-width: 1023px) {
  .splash {
    aspect-ratio: 4 / 3;
  }
}

@media (max-width: 960px) {
  .splash h2 {
    font-size: 36px;
  }

  .splash p {
    font-size: 28px;
  }

  .splash .call-to-action {
    font-size: 80%;
  }
}

@media (max-width: 600px) {
  .splash {
    aspect-ratio: 1 / 1;
  }

  .splash h2 {
    font-size: 24px;
  }

  .splash p {
    font-size: 20px;
  }

  .splash .call-to-action {
    font-size: 70%;
  }
}

@media (max-width: 480px) {
  .splash {
    aspect-ratio: 2 / 3;
  }
}

.banner-wrapper {
  display: flex;
  margin-block: 1em;
}

.banner-wrapper img {
  width: 100%;
}

.banner-wrapper img.banner-desktop {
  display: block;
}

.banner-wrapper img.banner-mobile {
  display: none;
}

@media (max-width: 960px) {
  .banner-wrapper img.banner-desktop {
    display: none;
  }

  .banner-wrapper img.banner-mobile {
    display: block;
  }
}

.quick-navigation ul {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
}

.quick-navigation a {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  font-size: 32px;
  display: flex;
  gap: 0.25em;
  padding-bottom: 0.5em;
  flex-direction: column;
  text-align: center;
  color: var(--color-black);
  text-decoration: none;
  transition: background-color 0.25s, color 0.25s;
}

.quick-navigation a:is(:hover, :active, :focus) {
  background-color: var(--color-black);
  color: var(--color-yellow);
}

@media (max-width: 960px) {
  .quick-navigation ul {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .quick-navigation a {
    flex-direction: row;
    align-items: center;
    background-color: var(--color-black);
    color: var(--color-yellow);
    padding: 3px;
  }
}

@media (max-width: 640px) {
  .quick-navigation a {
    flex-direction: column;
    align-items: stretch;
    background-color: transparent;
    color: var(--color-black);
    padding: 0;
    padding-bottom: 0.5em;
  }
}
