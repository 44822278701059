.event time {
  display: flex;
  gap: 0.35em;
}

.event .where-when {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-block: 2em 1em;
  align-items: flex-start;
}

.event .where-when h3 {
  margin-bottom: 0.5em;
  border-bottom: 2px solid var(--color-black);
}

.event .where-when p {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.event .where-when .icon {
  line-height: 1;
}

.event .where-when a {
  color: inherit;
  text-decoration: none;
}

.event .where-when a:hover,
.event .where-when a:focus {
  text-decoration: underline;
}

.event .external-link-icon {
  margin-left: 0.55em;
  line-height: 1;
  font-size: 0.75em;
}

.event .links {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.event .links:empty {
  display: none;
}

.event .where-when .links a {
  display: flex;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 0.75em;
  border-radius: 0.5em;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  font-size: 0.8em;
}

.event .where-when .links a:hover,
.event .where-when .links a:focus {
  text-decoration: none;
  background-color: var(--color-darkgray);
}
