.desktop-menu {
  display: flex;
  align-items: center;
}

@media (max-width: 840px) {
  .desktop-menu {
    display: none;
  }
}

.desktop-menu > ul {
  display: flex;
  list-style-type: none;
  gap: 3em;
  font-size: 16px;
}

@media (max-width: 1150px) {
  .desktop-menu > ul {
    gap: 2em;
  }
}

@media (max-width: 1080px) {
  .desktop-menu > ul {
    gap: 1.5em;
  }
}

@media (max-width: 960px) {
  .desktop-menu > ul {
    font-size: 14px;
    gap: 1em;
  }
}

.desktop-menu > ul > li > a {
  display: block;
  position: relative;
  padding: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}

.desktop-menu > ul > li > a:focus {
  outline: 0;
}

.desktop-menu > ul > li > a.selected {
  font-weight: bold;
}

.desktop-menu > ul > li > a::after {
  pointer-events: none;
  content: "";
  position: absolute;
  width: 100%;
  height: 0.12em;
  background-color: var(--color-black);
  bottom: -0.5em;
  left: 0;
  opacity: 0;
  transition: bottom 0.25s, opacity 0.25s;
}

.desktop-menu > ul > li > a.selected::after {
  height: 0.16em;
}

.desktop-menu > ul > li > a:is(:hover, :active, :focus)::after {
  bottom: 0;
  opacity: 1;
}
