.mobile-menu-button {
  display: none;
  position: relative;
  font-size: inherit;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  border: 0;
  box-shadow: 0 0 0.75em inset var(--color-yellow-dark);
  cursor: pointer;
  background-color: var(--color-yellow);
}

.mobile-menu-button > div {
  position: absolute;
  width: 60%;
  height: 3px;
  top: calc(50% - 1.5px);
  left: 20%;
  background-color: var(--color-black);
  transition: transform 0.35s, opacity 0.35s, top 0.35s;
}

.mobile-menu-button > div:nth-child(1) {
  transform: rotate(0deg);
  top: calc(50% - 1.5px - 20%);
}

.mobile-menu-button > div:nth-child(2) {
  transform: rotate(0deg);
  opacity: 1;
  top: calc(50% - 1.5px);
}

.mobile-menu-button > div:nth-child(3) {
  transform: rotate(0deg);
  top: calc(50% - 1.5px + 20%);
}

.mobile-menu-button.is-open > div:nth-child(1) {
  transform: rotate(225deg);
  top: calc(50% - 1.5px);
}

.mobile-menu-button.is-open > div:nth-child(2) {
  transform: rotate(315deg);
  opacity: 0;
  top: calc(50% - 1.5px);
}

.mobile-menu-button.is-open > div:nth-child(3) {
  transform: rotate(315deg);
  top: calc(50% - 1.5px);
}

@media (max-width: 840px) {
  .mobile-menu-button {
    display: flex;
  }
}

.mobile-menu {
  display: none;
  background-color: var(--color-yellow-pale);
  position: absolute;
  top: calc(100% - 0.5em);
  right: 1em;
  font-size: 16px;
  letter-spacing: 0.15em;
  box-shadow: 0 1em 1em 0.5em var(--color-black-25);
  transform: translateY(1em);
  opacity: 0;
  transition: transform 0.35s, opacity 0.35s;
  pointer-events: none;
}

.mobile-menu.is-open {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 840px) {
  .mobile-menu {
    display: block;
  }
}

.mobile-menu > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.mobile-menu > ul > li > a {
  display: block;
  padding: 1em;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-black-50);
  border-bottom: 1px solid var(--color-black-25);
}

.mobile-menu > ul > li > a.selected {
  color: var(--color-black);
}

.mobile-menu > ul > li:last-child > a {
  border-bottom: 0;
}
