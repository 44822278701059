.loader-wrapper {
  margin-block: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: 0.5em solid;
  border-color: var(--color-yellow-dark) transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
